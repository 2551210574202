import FormatText from './FormatText';
import {BaseTextProps, FontFamily} from './options';
import useSharedHTMLTextProps from './useSharedHTMLTextProps';
import useSharedTextCSS from './useSharedTextCSS';
import {CSSStyles} from '../../../types/CSSStyles';

import {css} from '@emotion/react';
import React from 'react';

export type HeadingTextSize = 'XXL' | 'XL' | 'L' | 'M' | 'S' | 'SS';

export interface Props extends BaseTextProps {
  readonly children: React.ReactNode;
  readonly size: HeadingTextSize;
  readonly isHeading?: boolean;
}

export default function HeadingText(props: Props): JSX.Element {
  const {children, isHeading = true, size} = props;
  let Component: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'div';
  const sharedCSS = useSharedTextCSS(props);
  const htmlProps = useSharedHTMLTextProps(props);

  let style: CSSStyles;
  switch (size) {
    case 'XXL':
      Component = 'h1';
      style = css({
        fontSize: 64,
      });
      break;
    case 'XL':
      Component = 'h2';
      style = css({
        fontSize: 56,
      });
      break;
    case 'L':
      Component = 'h3';
      style = css({
        fontSize: 44,
      });
      break;
    case 'M':
      Component = 'h4';
      style = css({
        fontSize: 32,
      });
      break;
    case 'S':
      Component = 'h5';
      style = css({
        fontSize: 24,
      });
      break;
    case 'SS':
      Component = 'h6';
      style = css({
        fontSize: 20,
      });
  }

  if (!isHeading) {
    Component = 'div';
  }

  return (
    <Component
      css={[
        sharedCSS,
        css({
          fontFamily: FontFamily.GARAMOND,
          fontWeight: 700,
          lineHeight: '1em',
        }),
        style,
      ]}
      {...htmlProps}
    >
      <FormatText>{children}</FormatText>
    </Component>
  );
}
